.navbar {
    background: #f9f6ec !important;
    border-bottom: 8px solid rgba(238,122,158,0.87) !important;
    height: 130px;
    font-size: 1.2rem;
    z-index: 200;
    padding: 5px 0 5px 0;
  }

  .navbar ~ .navbar{
    border-bottom: 1px solid rgba(238,122,158,0.87) !important;
    margin-top: 10px;
  }

  nav.navbar div.container-fluid {
    padding: 0 10px;
    margin: 0 270px;
  }

  .nav-link {
    color: #030008 !important;
  }

  .nav-link:hover, .nav-link a:hover {
    color: #ee7a9edf !important;
  }

  .navbar-collapse.collapse.show{
    z-index:20000;
    background-color: #f9f6ec;
    border: 1px solid #542E91;
  }

  #basic-login-dropdown ~ div {
    left: -200px;
  }

  @media only screen and (max-width: 1450px) {
    nav.navbar div.container-fluid {
      padding: 0 100px;
      margin: 0;
    }

  }

  @media only screen and (max-width: 1024px) {
    nav.navbar div.container-fluid {
      padding: 0 50px;
      margin: 0;
    }
  }

  @media only screen and (max-width: 668px) {
    nav.navbar div.container-fluid {
      padding: 0 10px;
      margin: 0;
      flex-direction: row-reverse !important;
      xjustify-content: center;
    }
    .navbar-toggler {
      background-color: white;
    }

    #basic-login-dropdown ~ div {
      left: 0;
    }
    
  }
  div.main-header a.effect1 {
    border: 20 0 0 0;
}
  div.main-header a.effect1:hover {
    animation: effect1 0.4s 1;
}
      
    @keyframes effect1 {
      0% {
        transform: scale(1.05);
      }
      20% {
          transform: scale(1.1);
      }
      100% {
          transform: scale(1);
      }
    }


    .referti {
      width: 300px;
    }

    @media only screen and (max-width: 668px) {

      .referti {
        width:250px;
      }

    }
 