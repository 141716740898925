.footer_container { 
  display: flex;
  position: fixed;
  left: 0;
  bottom: 0;

  background-color: #f9f6ec;

  height: 60px;
  min-height: fit-content;
  width: 100%;

  border-top: 8px solid rgba(238,122,158,0.87) !important;
  font-size: 1.2rem;;
  margin-top: auto;
  z-index: 100;

  justify-content: space-around;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .footer_container_chat { 
    height: 124px;
  }
  div.chatButtonRow > div, div.chatButtonRow > button {
    margin-bottom: 3px;
  }
}

